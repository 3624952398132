import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from '../Loading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { useStorefrontAdministrationConfig } from '../../hooks/StorefrontAdministrationConfigContext';
import CheckIcon from '@mui/icons-material/Check';
import { InputLabel, Select, MenuItem } from '@mui/material';
import TranslatableDataGrid from '../shared/TranslatableDataGrid';
import { ThemeProvider } from '@mui/material/styles';
import StorefrontAdminTheme from '../shared/MUIStyles/StorefrontAdminTheme';
import Typography from '@mui/material/Typography';

export const DashboardComponentAdministration = () => {
    const storeData = useStorefrontSettings();
    const localized = useStorefrontAdministrationConfig().labels;
    const [fetching, setFetching] = useState(false);
    const [rows, setRows] = useState([]);
    const [isEnabled, setIsEnabled] = React.useState(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const theme = StorefrontAdminTheme;
    const responseMessages = {
        "GenericFailure": localized.UpdateDashboardSettingError,
        "UpdateSuccess": localized.UpdateDashboardSettingSuccess,
    };
    useEffect(() => {
        getDashboardComponents();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>

                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton
                    slotProps={{
                        tooltip: { title: 'Filter list' }
                    }}
                    variant="outlined"
                    color="update"
                />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' }
                    }}
                    variant="outlined"
                    color="update"
                />

                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    slotProps={{
                        tooltip: { title: 'Export data' }
                    }}
                    variant="outlined"
                    color="update"
                />
            </GridToolbarContainer>
        );
    }

    const getDashboardComponents = () => {
        setFetching(true);
        Storefront.getDashboardComponents(storeData.storefrontNumber).then(loaded);
    }

    const updateDashboardVisibility = async (form) => {
        var request = {
            storefrontNumber: storeData.storefrontNumber,
            id: form.id ?? null,
            isEnabled: form.isEnabled
        };
        return await Storefront.updateDashboardComponentVisibility(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error(localized.UpdateDashboardSettingError);
                }
                getDashboardComponents();
            }
        });
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
        setFetching(false);
    }

    const mapRows = (data) => {
        var rows = [];
        data.dashboardComponentVisibilities.forEach((component) => {

            rows.push({
                id: component.componentId,
                settingName: component.settingName,
                isEnabled: component.isEnabled
            });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setIsEnabled(row.isEnabled);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const handleUpdateChange = (event) => {
        setIsEnabled(event.target.value);
    };

    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateDashboardVisibility(formJson, "update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle><h1>{localized.enableDashboardComponents}</h1></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <h3>{localized.ValuesEligibleForUpdateBelow}</h3>
                        </DialogContentText>
                        <InputLabel id="isEnabled-label">{localized.IsEnabled}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="isEnabled-label"
                            id="isEnabled"
                            name="isEnabled"
                            value={isEnabled}
                            label={localized.IsEnabled}
                            onChange={(x) => handleUpdateChange(x)}
                        >
                            <MenuItem value={true}>{localized.True}</MenuItem>
                            <MenuItem value={false}>{localized.False}</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="standard" color="error" onClick={handleCloseUpdate}>{localized.StorefrontAdminCancel}</Button>
                        <Button variant="standard" color="update" type="submit">{localized.StorefrontAdminUpdate}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'settingName', headerName: localized.DashboardSettingName, width: 400, headerAlign: 'center'},
        {
            field: 'isEnabled',
            headerName: localized.IsEnabled,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <strong>
                        {params.value ? <CheckIcon /> : null}
                    </strong>
                )
            }
        },
        {
            field: 'updateButton',
            headerName: localized.EnableDisable,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="standard"
                            color="update"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            {localized.EnableDisable}
                        </Button>
                    </strong>
                )
            },
            width:300,
            disableClickEventBubbling: true

        }
    ];

    return (
        <>
            {
                fetching && <Loading type="brand" />
            }
            {
                !fetching && (localized && Object.keys(localized).length > 0) &&
                <>
                    <ThemeProvider theme={theme}>
                        <Typography variant="h1" >{localized.DashboardWidgetAdministrationHeader}</Typography>
                        <Paper sx={{ height: 700, width: '100%' }}>
                            <TranslatableDataGrid rows={rows} columns={columns} insetToolbar={customToolbar} labels={localized} />
                        </Paper>
                        {openUpdate && renderUpdateButton(selectedRow)}
                    </ThemeProvider>
                </>
            }
        </>
    );
}

export default DashboardComponentAdministration;

