import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Loading from '../Loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { InputLabel, Select, MenuItem } from '@mui/material';
import { useStorefrontAdministrationConfig } from '../../hooks/StorefrontAdministrationConfigContext';
import CheckIcon from '@mui/icons-material/Check';
import TranslatableDataGrid from '../shared/TranslatableDataGrid';
import { ThemeProvider } from '@mui/material/styles';
import StorefrontAdminTheme from '../shared/MUIStyles/StorefrontAdminTheme';
import Typography from '@mui/material/Typography';

export const CarrierDetailAdministration = () => {
    const localized = useStorefrontAdministrationConfig().labels;
    const [carrierData, setCarrierData] = useState([]);
    const [freightHandlingCodeData, setFreightHandlingCodeData] = useState([]);
    const [labelData, setLabelData] = useState([]);
    const [currencyCodeData, setCurrencyCodeData] = useState([]);
    const [countryStateCodeData, setCountryStateCodeData] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [carrierId, setCarrierId] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [filteredStateData, setFilteredStateData] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [freightCode1, setFreightCode1] = useState('');
    const [freightCode2, setFreightCode2] = useState('');
    const [freightCode1Label, setFreightCode1Label] = useState('');
    const [freightCode2Label, setFreightCode2Label] = useState('');
    const [showExtraInformation, setShowExtraInformation] = useState(true);
    const [effectiveDateValue, setEffectiveDateValue] = useState(new Date());
    const [expirationDateValue, setExpirationDateValue] = useState(new Date());
    const [rows, setRows] = useState([]);
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const theme = StorefrontAdminTheme;
    const responseMessages = {
        "GenericFailure": localized.CarrierDetailGenericFailure,
        "DeleteSuccess": localized.CarrierDetailsSuccessfulDelete,
        "UpdateSuccess": localized.CarrierDetailsSuccessfulUpdate,
        "AddSuccess": localized.CarrierDetailsSuccessfulAdd
    };
    var addEffectiveDateValue = new Date();
    var addExpirationDateValue = new Date();

    useEffect(() => {
            getCarrierDetails();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer  >
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton
                  slotProps={{
                      tooltip: { title: 'Filter list' }
                  }}      
                  variant="outlined"
                  color="update"
                    
                />
                <GridToolbarDensitySelector
                    slotProps={{                        
                        tooltip: { title: 'Change density' }
                    }}
                    variant="outlined"
                    color="update"
                />

                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    slotProps={{
                        tooltip: { title: 'Export data' }
                    }}
                    variant="outlined"
                    color="update"
                />
                <strong>
                    <Button
                        variant="standard"
                        color="success"
                        style={{ marginLeft: 16 }}
                        onClick={(e)=>handleClickOpenAdd(e)}
                    >
                        {localized.StorefrontAdminAdd}
                    </Button>
                </strong>
            </GridToolbarContainer>
        );
    }

    const getCarrierDetails = () => {
        setFetching(true);
        Storefront.getCarrierDetails(storeData.storefrontNumber).then(loaded);
    }

    const updateCarrierDetails = async (form, type) => {
        var useEffectiveDate = type === "add" ? addEffectiveDateValue : effectiveDateValue;
        var useExpirationDate = type === "add" ? addExpirationDateValue : expirationDateValue;     
        var request = {
            id:form.id ?? null,
            storefrontNumber: storeData.storefrontNumber,   
            carrierId: form.carrierId,                     
            country: form.countryCode,
            state: form.stateCode,
            currencyCode: form.currencyCode,
            thresholdAmount: form.amount,
            freightCode1: form.freightCode1,
            freightCode2: form.freightCode2,
            label1: form.freightCode1Label,
            label2: form.freightCode2Label,
            showInformation: form.showExtraInformation,
            effectiveDate: useEffectiveDate,
            expirationDate: useExpirationDate,
            operationType: type
        };
        addEffectiveDateValue = new Date();
        addExpirationDateValue = new Date();
        return await Storefront.updateCarrierDetails(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error(localized.CarrierDetailsErrorNotification);
                }
                getCarrierDetails();
            }
        });
    }

    const loaded = (data) => {
        if (data && data.carrierDetails && data.freightHandlingCodes) {
            setCarrierData(data.carriers);
            setFreightHandlingCodeData(data.freightHandlingCodes);
            setLabelData(data.labelNames);
            setCurrencyCodeData(data.currencyCodes);
            setCountryStateCodeData(data.countryStateCodes);
            mapRows(data.carrierDetails);
        }
        setFetching(false)
    }

    const mapRows = (carrierDetails) => {
        var rows = [];
        carrierDetails.forEach((carrierDetail) => {
            rows.push({
                id: carrierDetail.id,
                carrierId: carrierDetail.carrierId,
                companyCode: carrierDetail.companyCode,
                country: carrierDetail.country,
                state: carrierDetail.state,
                currencyCode: carrierDetail.currencyCode,
                thresholdAmount: carrierDetail.amount,
                belowThresholdCode: carrierDetail.freightCode1,
                atExceedsThresholdcode: carrierDetail.freightCode2,
                correspondingLabel1: carrierDetail.freightCode1Label,
                correspondingLabel2: carrierDetail.freightCode2Label,
                showInfo: carrierDetail.showExtraInformation,
                effectiveDate: carrierDetail.effectiveDate,
                expirationDate: carrierDetail.expirationDate
            });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setCurrencyCode(row.currencyCode);
        setFreightCode1(row.atExceedsThresholdcode);
        setFreightCode2(row.belowThresholdCode);
        setFreightCode1Label(row.correspondingLabel1);
        setFreightCode2Label(row.correspondingLabel2);
        setShowExtraInformation(row.showInfo);
        setEffectiveDateValue(row.effectiveDate);
        setExpirationDateValue(row.expirationDate);
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setCarrierId("");
        setCurrencyCode("");
        setFreightCode1("");
        setFreightCode2("");
        setFreightCode1Label("");
        setFreightCode2Label("");
        setShowExtraInformation(true);
        setOpenUpdate(false);
    };
    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setEffectiveDateValue(new Date());
        setExpirationDateValue(new Date());
    };

    const handleClickOpenAdd = (e) => {
       e.stopPropagation();
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
        addEffectiveDateValue = new Date();
        addExpirationDateValue = new Date();
        setCarrierId("");
        setCountryCode("");
        setStateCode("");
        setFilteredStateData([]);
        setCurrencyCode("");
        setFreightCode1("");
        setFreightCode2("");
        setFreightCode1Label("");
        setFreightCode2Label("");
        setShowExtraInformation(true);
    };

    const handleCarrierIdChange = (event) => {
        setCarrierId(event.target.value);
    }

    const handleCurrencyCodeChange = (event) => {
        setCurrencyCode(event.target.value);
    }

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        var filteredStates = countryStateCodeData.filter((country) => country.countryCode === event.target.value);
        setFilteredStateData(filteredStates);
    }

    const handleStateCodeChange = (event) => {
        setStateCode(event.target.value);
    }

    const handleFreightCode1Change = (event) => {
        setFreightCode1(event.target.value);
    }
    const handleFreightCode2Change = (event) => {
        setFreightCode2(event.target.value);
    }

    const handleFreightCode1LabelChange = (event) => {
        setFreightCode1Label(event.target.value);
    }

    const handleFreightCode2LabelChange = (event) => {
        setFreightCode2Label(event.target.value);
    }

    const handleShowExtraInformationChange = (event) => {
        setShowExtraInformation(event.target.value);
    }

    const handleEffectiveDateChangeUpdate = (e) => {
        setEffectiveDateValue(e);
    }

    const handleExpirationDateChangeUpdate = (e) => {
        setExpirationDateValue(e);
    }

    const handleEffectiveDateChangeAdd = (e) => {
        addEffectiveDateValue = e;
    }

    const handleExpirationDateChangeAdd = (e) => {
        addExpirationDateValue = e;
    }

    const renderDeleteButton = (row) => {
        return (
            <>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateCarrierDetails(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle><h1>{localized.AreYouSure}</h1></DialogTitle>
                    <DialogContent>
                        <h3>{row.carrierId}</h3>
                        <DialogContentText>
                            {localized.AreYouSureDeleteCarrierDetail}
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseDelete}>{localized.StorefrontAdminCancel}</Button>
                        <Button variant="contained" color="error" type="submit">{localized.StorefrontAdminDelete}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("carrierId", row.carrierId);
                            formData.append("id", row.id);
                            formData.append("country", row.country);
                            formData.append("state", row.state);
                            const formJson = Object.fromEntries(formData.entries());
                            updateCarrierDetails(formJson, "update");
                            handleCloseUpdate();

                        },
                    }}
                >
                    <DialogTitle><h1>{localized.UpdateCarrierDetails}</h1></DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{paddingBottom:"15px"} }>
                            <h3>{localized.ValuesEligibleForUpdateBelow}</h3>
                        </DialogContentText>
                        <InputLabel required id="currencyCode-label">{localized.CurrencyCode}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="currencyCode-label"
                            id="currencyCode"
                            name="currencyCode"
                            value={currencyCode}
                            label={localized.CurrencyCode}
                            onChange={handleCurrencyCodeChange}
                        >
                            {currencyCodeData && currencyCodeData.map((currencyCode) => (
                                <MenuItem key={currencyCode.currencyCode1}value={currencyCode.currencyCode1}>
                                        {currencyCode.currencyCode1 + " - " + currencyCode.description}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="amount-label">{localized.Amount}</InputLabel>
                        <TextField
                            defaultValue={row.thresholdAmount}
                            inputProps={{ maxLength: 15 }}
                            required
                            placeholder={localized.Amount}
                            margin="dense"
                            id="amount"
                            name="amount"
                            type="decimal"
                            fullWidth
                            variant="outlined"
                        />
                        <InputLabel required id="freightCode1-label">{localized.FreightCode1}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="freightCode1-label"
                            id="freightCode1"
                            name="freightCode1"
                            value={freightCode1}
                            label={localized.FreightCode1}
                            onChange={handleFreightCode1Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="freightCode2-label">{localized.FreightCode2}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="freightCode2-label"
                            id="freightCode2"
                            name="freightCode2"
                            value={freightCode2}
                            label={localized.FreightCode2}
                            onChange={handleFreightCode2Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="freightCode1Label-label">{localized.AboveCodeLabel}</InputLabel>
                        <Select
                            fullWidth
                            labelId="freightCode1Label-label"
                            id="freightCode1Label"
                            name="freightCode1Label"
                            value={freightCode1Label}
                            label={localized.AboveCodeLabel}
                            onChange={handleFreightCode1LabelChange}
                        >
                            {labelData && labelData.map((label) => (
                                <MenuItem key={label} value={label}>{label}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="freightCode2Label-label">{localized.BelowCodeLabel}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="freightCode2Label-label"
                            id="freightCode2Label"
                            name="freightCode2Label"
                            value={freightCode2Label}
                            label={localized.BelowCodeLabel}
                            onChange={handleFreightCode2LabelChange}
                        >
                            {labelData && labelData.map((label) => (
                                <MenuItem key={label} value={label}>{label}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="showExtraInformation-label">{localized.ShowExtraInformation}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="showExtraInformation-label"
                            id="showExtraInformation"
                            name="showExtraInformation"
                            value={showExtraInformation}
                            label={localized.ShowExtraInformation}
                            onChange={handleShowExtraInformationChange}
                        >
                            <MenuItem value={true}>{localized.True}</MenuItem>
                            <MenuItem value={false}>{localized.False}</MenuItem>
                        </Select>
                        <InputLabel required >{localized.EffectiveDate + ":  "}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        multiline: true,
                                        inputProps: { style: { fontSize: 14} }                                        
                                    }

                                }}
                                defaultValue={new Date(row.effectiveDate)}
                                value={new Date(effectiveDateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleEffectiveDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                        <InputLabel required >{localized.ExpirationDate + ":  "}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        multiline: true,
                                        inputProps: { style: { fontSize: 14 } }
                                    }
                                
                                }}
                                defaultValue={new Date(row.expirationDate)}
                                value={new Date(expirationDateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleExpirationDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="standard" color="error" onClick={handleCloseUpdate}>{localized.StorefrontAdminCancel}</Button>
                        <Button variant="standard" color="update" type="submit">{localized.StorefrontAdminUpdate}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderAddButton = () => {
        const uniqueCountryObjects = new Map();
        for (const country of countryStateCodeData) {
            uniqueCountryObjects.set(country.countryCode, { "countryCode": country.countryCode, "countryDescription": country.countryDescription })          
        }
        const uniqueCountryCodeData = Array.from(uniqueCountryObjects.values());

        return (
            <>                
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    
                    PaperProps={{
                        component: 'form',
                        style: {
                            width:"400px"
                        },
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());                            
                            updateCarrierDetails(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle><h1>{localized.AddCarrier}</h1></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.FillRequiredFieldsBelow}
                        </DialogContentText>
                        <InputLabel required id="carrierId-label">{localized.CarrierId}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="carrierId-label"
                            id="carrierId"
                            name="carrierId"
                            value={carrierId}
                            label={localized.CarrierId}
                            onChange={handleCarrierIdChange}
                        >
                            {carrierData && carrierData.map((carrier) => (
                                <MenuItem key={carrier.carrierId} value={carrier.carrierId}>
                                    {carrier.carrierId + " - " + carrier.carrierName}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="currencyCode-label">{localized.CurrencyCode}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="currencyCode-label"
                            id="currencyCode"
                            name="currencyCode"
                            value={currencyCode}
                            label={localized.CurrencyCode}
                            onChange={handleCurrencyCodeChange}
                        >
                            {currencyCodeData && currencyCodeData.map((currencyCode) => (
                                <MenuItem key={currencyCode.currencyCode1} value={currencyCode.currencyCode1}>
                                    {currencyCode.currencyCode1 + " - " + currencyCode.description}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="countryCode-label">{localized.CountryCode}</InputLabel>
                        <Select
                            required    
                            fullWidth
                            labelId="countryCode-label"
                            id="countryCode"
                            name="countryCode"
                            value={countryCode}
                            label={localized.CountryCode}
                            onChange={handleCountryCodeChange}
                        >
                            {uniqueCountryCodeData && uniqueCountryCodeData.map((countryCode) => (
                                <MenuItem key={countryCode.countryCode} value={countryCode.countryCode}>
                                    {countryCode.countryCode + " - " + countryCode.countryDescription}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="stateCode-label">{localized.StateCode}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="stateCode-label"
                            id="stateCode"
                            name="stateCode"
                            value={stateCode}
                            label={localized.StateCode}
                            onChange={handleStateCodeChange}
                        >
                            {filteredStateData && filteredStateData.map((stateCode) => (
                                <MenuItem key={stateCode.stateCode} value={stateCode.stateCode}>
                                    {stateCode.stateCode + " - " + stateCode.stateDescription}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="amount-label">{localized.Amount}</InputLabel>
                        <TextField
                            inputProps={{ maxLength: 15 }}
                            required
                            margin="dense"
                            id="amount"
                            name="amount"
                            placeholder={localized.Amount}
                            type="decimal"
                            fullWidth
                            variant="outlined"
                        />
                        <InputLabel required id="addFreightCode1-label">{localized.FreightCode1}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="addFreightCode1-label"
                            name="freightCode1"
                            id="freightCode1"
                            value={freightCode1}
                            label={localized.FreightCode1}
                            onChange={handleFreightCode1Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="addFreightCode2-label">{localized.FreightCode2}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="addFreightCode2-label"
                            id="freightCode2"
                            name="freightCode2"
                            value={freightCode2}
                            label={localized.FreightCode2}
                            onChange={handleFreightCode2Change}
                        >
                            {freightHandlingCodeData && freightHandlingCodeData.map((freightCode) => (
                                <MenuItem key={freightCode.code} value={freightCode.code}>{freightCode.code}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="addFreightCode1Label-label">{localized.AboveCodeLabel}</InputLabel>
                        <Select
                            required    
                            fullWidth
                            labelId="addFreightCode1Label-label"
                            id="freightCode1Label"
                            name="freightCode1Label"
                            value={freightCode1Label}
                            label={localized.AboveCodeLabel}
                            onChange={handleFreightCode1LabelChange}
                        >
                            {labelData && labelData.map((label) => (
                                <MenuItem key={label} value={label}>{label}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="addFreightCode2Label-label">{localized.BelowCodeLabel}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="addFreightCode2Label-label"
                            id="freightCode2Label"
                            name="freightCode2Label"
                            value={freightCode2Label}
                            label={localized.BelowCodeLabel}
                            onChange={handleFreightCode2LabelChange}
                        >
                            {labelData && labelData.map((label) => (
                                <MenuItem key={label} value={label}>{label}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel required id="showExtraInformation-label">{localized.ShowExtraInformation}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="showExtraInformation-label"
                            id="showExtraInformation"
                            name="showExtraInformation"
                            value={showExtraInformation}
                            label={localized.ShowExtraInformation}                          
                            onChange={(x)=>handleShowExtraInformationChange(x)}
                        >
                            <MenuItem value={true}>{localized.True}</MenuItem>
                            <MenuItem value={false}>{localized.False}</MenuItem>
                        </Select>
                        <InputLabel required>{localized.EffectiveDate + ":  "}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker

                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        multiline: true,
                                        inputProps: { style: { fontSize: 14 } }
                                    }

                                }}
                                defaultValue={new Date()}
                                value={addEffectiveDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleEffectiveDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                        <InputLabel required>{localized.ExpirationDate + ":  "}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        multiline: true,
                                        inputProps: { style: { fontSize: 14 } }
                                    }
                                }}
                                defaultValue={new Date()}
                                value={addExpirationDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleExpirationDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="standard" color="error" onClick={handleCloseAdd}>{localized.StorefrontAdminCancel}</Button>
                        <Button variant="standard" color="success" type="submit">{localized.StorefrontAdminAdd}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'carrierId', headerName: localized.CarrierId, width: 75, headerAlign: 'center'},
        { field: 'companyCode', headerName: localized.CompanyCode, width:75, headerAlign:'center'},
        { field: 'country', headerName: localized.CountryCode,  width:75, headerAlign:'center'},
        { field: 'state', headerName: localized.StateCode, width: 50, headerAlign:'center'},
        { field: 'currencyCode', headerName: localized.CurrencyCode, width:75, headerAlign:'center'},
        { field: 'thresholdAmount', headerName: localized.Amount, width:100, headerAlign:'center'},
        { field: 'belowThresholdCode', headerName: localized.FreightCode1, width:60, headerAlign:'center'},
        { field: 'atExceedsThresholdcode', headerName: localized.FreightCode2, width:60, headerAlign:'center'},
        { field: 'correspondingLabel1', headerName: localized.AboveCodeLabel, width:150, headerAlign:'center'},
        { field: 'correspondingLabel2', headerName: localized.BelowCodeLabel, width:150, headerAlign:'center'},
        {
            field: 'showInfo',
            headerName: localized.ShowExtraInformation,
            width: 85,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <strong>
                        {params.value ? <CheckIcon color="success"/> : null}
                    </strong>
                )
            }
        },
        { field: 'effectiveDate', headerName: localized.EffectiveDate, width: 100, headerAlign: 'center' },
        { field: 'expirationDate', headerName: localized.ExpirationDate, width: 100, headerAlign: 'center' },

        {
            field: 'updateButton',
            headerName: localized.StorefrontAdminUpdate,
            headerAlign: 'center',
            width:100,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="standard"
                            color="update"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            {localized.StorefrontAdminUpdate}
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true

        },
        {
            field: 'deleteButton',
            headerAlign: 'center',
            width:100,
            headerName: localized.StorefrontAdminDelete,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="standard"
                            color="error"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            {localized.StorefrontAdminDelete}   
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            {
                fetching && <Loading type="brand" />
            }
            {
                !fetching && (localized && Object.keys(localized).length > 0) &&
                <>
                    <ThemeProvider theme={theme}>
                        <Typography variant="h1">{localized.CarrierDetailAdministrationHeader}</Typography>
                        <Paper sx={{
                            height: 700,
                            width: '100%'                            
                    }}>
                        
                            <TranslatableDataGrid rows={rows} columns={columns} insetToolbar={customToolbar} labels={localized} />
                        
                        </Paper>
                        {openUpdate && renderUpdateButton(selectedRow)}
                        {openDelete && renderDeleteButton(selectedRow)}
                        {openAdd && renderAddButton()}
                    </ThemeProvider>
                </>
            }
        </>
    );
}

export default CarrierDetailAdministration;

