import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const StorefrontAdminTheme = () => {

    const globalTheme = createTheme({
      palette: {
        primary: {
              main: "#118ABD"
        },
        update: {
            main: "#118ABD"
        },
        secondary: {
          main: "#11cb5f"
            },
        success: {
            main:"#7ebd42"
        },
        error: {
            main: "#CE3333"
        },
        },
        typography: {
            fontSize: 20,
            h1: {
                fontSize: 40,
                fontWeight: "bold"
            }            
        }
    });
    
    const theme = createTheme(
        {
            components: {
              MuiGridToolbarFilterButton: {
                    variants: [
                      {
                          props: {
                              variant: "outlined",
                              color: "update"
                            },
                           style: {
                              textTransform: "none",
                              border: "none",
                              backgroundColor: globalTheme.palette.update.main,
                              color: "#fff",
                              borderRadius: 8,
                              "&:hover": {
                                  color: globalTheme.palette.update.main,
                                  backgroundColor: alpha(globalTheme.palette.update.main, 0.16)
                              },
                              "&:active": {
                                  backgroundColor: globalTheme.palette.update.dark,
                                  color: "#fff"
                              }
                          }
                      }
                  ]
            },
          MuiButton: {
            variants: [
              {
                props: { variant: "standard", color: "update" },
                    style: {
                        textTransform: "none",
                        border: "none",
                        backgroundColor: globalTheme.palette.update.main,
                        color: "#fff",
                        borderRadius: 8,
                        "&:hover": {
                          color: globalTheme.palette.update.main,
                          backgroundColor: alpha(globalTheme.palette.update.main, 0.16)
                        },
                        "&:active": {
                          backgroundColor: globalTheme.palette.update.dark,
                          color: "#fff"
                        }
                    }
              },
              {
                  props: { variant: "standard", color: "success" },
                  style: {
                      textTransform: "none",
                      border: "none",
                      backgroundColor: globalTheme.palette.success.main,
                      color: "#fff",
                      borderRadius: 8,
                      "&:hover": {
                          color: globalTheme.palette.success.main,
                          backgroundColor: alpha(globalTheme.palette.success.main, 0.16)
                      },
                      "&:active": {
                          backgroundColor: globalTheme.palette.success.dark,
                          color: "#fff"
                      }
                  }
              },
              {
                  
                  props: { variant: "standard", color: "error" },
                  style: {
                      textTransform: "none",
                      border: "none",
                      backgroundColor: globalTheme.palette.error.main,
                      color: "#fff",
                      borderRadius: 8,
                      "&:hover": {
                          color: globalTheme.palette.error.main,
                          backgroundColor: alpha(globalTheme.palette.error.main, 0.16)
                      },
                      "&:active": {
                          backgroundColor: globalTheme.palette.error.dark,
                          color: "#fff"
                      }
                  
                }
              }
            ]
          }
        }
      },
      globalTheme
    );

return theme;
}

export default StorefrontAdminTheme;