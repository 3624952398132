import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box  from '@mui/material/Box';
import Notifications from '../../services/Notifications';
import Loading from '../Loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { GridToolbarContainer,GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid'; 
import Paper from '@mui/material/Paper';
import { InputLabel, Select, MenuItem } from '@mui/material';
import TranslatableDataGrid from '../shared/TranslatableDataGrid';
import { useStorefrontAdministrationConfig } from '../../hooks/StorefrontAdministrationConfigContext';
import { ThemeProvider } from '@mui/material/styles';
import StorefrontAdminTheme from '../shared/MUIStyles/StorefrontAdminTheme';
import Typography from '@mui/material/Typography';

export const LabelAdministration =()=> {
    const [translationLocaleData, setTranslationLocaleData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [openUpdate, setOpenUpdate] = useState(false);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const storefrontAdministrationConfig = useStorefrontAdministrationConfig();
    const localized = storefrontAdministrationConfig.labels;
    const storeData = useStorefrontSettings();
    const theme = StorefrontAdminTheme;
    const responseMessages = {
        "GenericFailure": localized.LabelGenericFailure,
        "UpdateSuccess": localized.LabelUpdateSuccess
    };

    useEffect(() => {
        getTranslationLocales();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <InputLabel id="select-language-label">{localized.SelectALanguage}</InputLabel>
                <Select
                    fullWidth
                    labelId="select-language-label"
                    id="selectedLanguage"
                    value={selectedLanguage}
                    label="Selected Language"
                    onChange={handleLanguageChange}
                >
                    {translationLocaleData && translationLocaleData.languages && translationLocaleData.languages.map((locale) => (
                        <MenuItem key={locale.translationLocaleId} value={locale.translationLocaleId}>{locale.displayName}</MenuItem>
                    ))}
                </Select>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton
                    slotProps={{
                        tooltip: { title: 'Filter list' }
                    }}
                    variant="outlined"
                    color="update"
                />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' }
                    }}
                    variant="outlined"
                    color="update"
                />

                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    slotProps={{
                        tooltip: { title: 'Export data' }
                    }}
                    variant="outlined"
                    color="update"
                />
            </GridToolbarContainer>
        );
    }

    const updateLabel = async (form) => {
        var request = {
            labelId: form.id ?? null,
            translationlocaleId: selectedLanguage,
            labelValue: form.translation
        };
        return await Storefront.updateLabel(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error(localized.LabelGenericFailure);
                }
                getTranslationLabels(selectedLanguage);
            }
        });
    }

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        getTranslationLabels(event.target.value);
    }

    const getTranslationLocales = () => {
        setFetching(true);
        Storefront.getStorefrontTranslationLocales(storeData.storefrontNumber).then( loadTranslations);
    }

    const getTranslationLabels = (translationLocaleId) => {
        Storefront.getStorefrontTranslations(storeData.storefrontNumber, translationLocaleId).then(loaded);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const loadTranslations = (data) => {
        if (data) {
            setTranslationLocaleData(data)
            setSelectedLanguage(data.languages[0].translationLocaleId);
            getTranslationLabels(data.languages[0].translationLocaleId);
        }
        else {
            setTranslationLocaleData([])
        }
        setFetching(false);
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
    }

    const mapRows = (data) => {
        var rows = [];
        data.translations.forEach((translation) => {

            rows.push({ id: translation.labelId, components: translation.componentName, labelName: translation.labelName, labelValue: translation.labelValue });
        });
        setRows(rows);
    }

    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateLabel(formJson);
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle><h1>{localized.UpdateLabel}</h1></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <h3>{localized.ValuesEligibleForUpdateBelow}</h3>
                        </DialogContentText>
                        <TextField
                            defaultValue={row.translation}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="translation"
                            name="translation"
                            label={localized.LabelValue}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="standard" color="error" onClick={handleCloseUpdate}>{localized.StorefrontAdminCancel}</Button>
                        <Button variant="standard" color="update" type="submit">{localized.StorefrontAdminUpdate}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'labelName', headerName: localized.LabelReferenceName, width: 200, headerAlign: 'center' },
        { field: 'components', headerName: localized.ComponentsImpacted, width: 200, headerAlign: 'center' },
        { field: 'labelValue', headerName: localized.LabelValue, width: 1000, headerAlign: 'center' }, {
            field: "updateButton",
            headerName: localized.StorefrontAdminUpdate,
            headerAlign:'center',
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="standard"
                            color="update"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            {localized.StorefrontAdminUpdate}
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];

    return ( 
        <>  
            {
                fetching && <Loading type="brand" />
            }
            {
                !fetching && translationLocaleData && translationLocaleData.languages && (localized && Object.keys(localized).length > 0) &&
                <>
                    <ThemeProvider theme={theme}>
                        <Typography variant="h1">{localized.LabelsAdministrationHeader}</Typography> 
                        <Paper sx={{ height: 700, width: '100%' }}>
                            <TranslatableDataGrid rows={rows} columns={columns} insetToolbar={customToolbar} labels={localized} />
                        </Paper>
                        {openUpdate && renderUpdateButton(selectedRow)}   
                    </ThemeProvider>
                </>
            }
        </>
            
       
    );
}

export default LabelAdministration;

